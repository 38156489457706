.page {
  height: 100vh;
  display: flex;
}

.halfPageWrapper {
  height: 100%;
  width: 100%;
  padding: 20px;
  flex-shrink: 0;

  @media screen and (min-width: 992px) {
    width: 50%;
  }
}

.halfPageContainer {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 28px;
  border-radius: 20px;
}
