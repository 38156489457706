.Avatar {
  --color-user: var(--color-primary);
  flex: none;
  align-items: center;
  justify-content: center;
  width: 3.375rem;
  height: 3.375rem;
  border-radius: 50%;
  // background: linear-gradient(var(--color-white) -125%, var(--color-user));
  background: var(--color-user);
  color: white;
  font-weight: bold;
  display: flex;
  white-space: nowrap;
  user-select: none;

  img:not(.emoji) {
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
  &.size-jumbo {
    img:not(.emoji) {
      border-radius: 0%;
    }
  }

  .emoji {
    width: 1rem;
    height: 1rem;
  }

  i {
    font-size: 2.5rem;

    &.icon-reply-filled {
      transform: scale(0.7);
    }
  }

  &.size-micro {
    width: 1rem;
    height: 1rem;
    font-size: 0.5rem;

    .emoji {
      width: 0.5625rem;
      height: 0.5625rem;
    }
  }

  &.size-tiny {
    width: 2rem;
    height: 2rem;
    font-size: 0.875rem;

    .emoji {
      width: 0.875rem;
      height: 0.875rem;
    }
  }

  &.size-small {
    width: 2.125rem;
    height: 2.125rem;
    font-size: 0.875rem;

    .emoji {
      width: 0.875rem;
      height: 0.875rem;
    }
  }

  &.size-medium {
    width: 2.75rem;
    height: 2.75rem;
    font-size: 1.1875rem;

    .emoji {
      width: 1rem;
      height: 1rem;
    }
  }

  &.size-large {
    font-size: 1.3125rem;

    i {
      font-size: 3.5rem;
    }

    .emoji {
      width: 1.3125rem;
      height: 1.3125rem;
    }
  }

  &.size-jumbo {
    width: 7.5rem;
    height: 7.5rem;
    font-size: 3.5rem;

    i {
      font-size: 6rem;
    }

    .emoji {
      width: 3.5rem;
      height: 3.5rem;
    }
  }

  &.online {
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0.0625rem;
      right: 0.0625rem;
      width: 0.875rem;
      height: 0.875rem;
      border-radius: 50%;
      border: 2px solid var(--color-background);
      background-color: #F4BB2E;
      flex-shrink: 0;
    }
  }

  &.interactive {
    cursor: pointer;
  }
}
