.GroupCallParticipant {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
  padding: 0.5rem 0.75rem;
  border-radius: 0.75rem;
  transition: 0.15s ease-out background-color;
  cursor: pointer;

  &:hover {
    background: #2f363e;
  }

  audio {
    display: none;
  }

  .Avatar {
    margin-right: 1rem;
  }

  .info {
    min-width: 0;
    display: flex;
    flex-direction: column;

    .name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .about {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: #848d94;
      font-size: 0.75rem;

      &.blue {
        color: #4da6e0;
      }

      &.green {
        color: #57bc6c;
      }

      &.red {
        color: #ff706f;
      }
    }
  }

  .microphone {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.75rem;
    height: 2.75rem;
    margin-left: auto;
    font-size: 1.5rem;
    color: #ff706f;
  }

  &.can-self-unmute {
    .microphone {
      color: #848d94;
    }
  }

  .streams {
    cursor: pointer;
    display: flex;
  }
}
