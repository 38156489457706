.container {
  color: #000000;
  background-color: #F4BB2E;
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logoTitle {
  font-size: 24px;
  font-weight: 700;
  margin-left: 8px;
}

.downloadButtonsWrapper {
  display: flex;
}

.downloadButton {
  margin: 0 8px;

  & > img {
    max-width: 100%;
  }
}

.title {
  font-size: 48px;
  font-weight: 700;
}

.subtitle {
  font-size: 20px;
  font-weight: 500;
}
